
.contents_container {
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  flex-wrap: nowrap;
  padding: 10px;
  width: 100%;
}

.sibling_rail {
  position: absolute;
  box-sizing: border-box;
  left: -13px;
  top: -5px;
  bottom: 0px;
  width: 0;
  border-left: 1px solid rgb(50, 50, 50);
}

.position_capture {
  position: relative;
  width: max-content;
}

